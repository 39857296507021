import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SessionService } from './session.service';
import firebase from 'firebase/compat';
// import { FirebaseService } from './firebase.service';


interface ImageRequire {
  order: number;
  done: boolean;
  title: string;
  description: string;
  s3_url: string;
  signed_form: null;
}

@Injectable()
export class RemoteImageService {
  static instance: RemoteImageService;
  // private firebase: FirebaseService;
  private resolveList: { [key: string]: any } = {};
  private firebaseRef: firebase.database.Reference;

  constructor(private httpClient: HttpClient, private sessionService: SessionService) {
    if (!RemoteImageService.instance) {
      // this.firebase = new FirebaseService();
      // this.firebase.auth().then();

      const user_id = this.sessionService.data.user.id;

      // this.firebaseRef = this.firebase.register(`/mobile-file/${user_id}/`, (snapshot) => {
      //   const list = snapshot.val() || {};

      //   Object.keys(list).forEach(key => {
      //     if (list[key].done && this.resolveList[key]) {
      //       const resolve = this.resolveList[key];
      //       delete this.resolveList[key];

      //       // console.log(list[key].file_key);
      //       const pathKey = list[key].file_key;

      //       this.httpClient.get('/api/aws/file-url/', { params: { key: pathKey } }).pipe(
      //         map(res => res)
      //       ).subscribe(res => resolve({ key: pathKey, signed_url: res['signed_url'] }));
      //     }
      //   });
      // });
    }

    return RemoteImageService.instance;
  }

  public require(title: string, description: string): Promise<{ key: string, signed_url: string }> {
    return new Promise(resolve => {
      this.get().subscribe(res => {

        const data = {
          order: Date.now(),
          done: false,
          title: title,
          file_key: res['key'],
          description: description,
          signed_form: res['signed_form']
        };

        this.firebaseRef.push(data).then(item => {
          const fbKey = item.key;
          this.resolveList[fbKey] = resolve;
        });

      });
    });
  }

  // Gera URL com o path que a imagem deverá ser salva
  private get() {
    return this.httpClient.get('/api/aws/mobile-storage/', { params: { path: 'photos', extension: 'jpg' } }).pipe(
      map(res => res)
    );
  }
}
